// General:
export const urlProtocolRegExp: RegExp = /(https?:\/\/)?(www.)?/i;
export const cyrillicRegExp: RegExp = new RegExp(/[а-я]/gi);
export const findSpaceRegExp = new RegExp(/\s+/g);

// Form:
export const notSpacePattern: RegExp = /^(?=.*[^\s]).+$/;
export const emailPattern = /^[A-Za-z0-9!#$%&'*+_.-/=?^`{|}~\-]+@[A-Za-z0-9+_.-]+\.[A-Za-z]{2,}$/;
export const phonePattern = /^[\d()+\-]+$/;
export const passwordRegExp: RegExp = /^[A-Za-zА-Яа-я\d\s]+$/;
export const noDigitsReqExp: RegExp = /^\D*$/;
export const domainName: RegExp = /^[^\s\.]{1,63}\.[^\s\.]{1,63}[^\s]{0,253}$/;
// Disjunction "|" here is to sepparate subdomain name match and whole domain name match, so it matches either just subdomain name or
// whole tracking domain name.
export const trackingDomainName =
  /^((?!-)[a-z0-9-]{0,62}[a-z0-9]|((?!-)[a-z0-9-]{0,62}[a-z0-9]\.)+[a-z0-9]{2,})$/i;

// Pages
export const candidatePageRegExp: RegExp = new RegExp(
  /(profile\/)((?:\d+[a-z]|[a-z]+\d)[a-z\d]*)+/g
);
export const companyPageRegExp: RegExp = new RegExp(/(company\/)[0-9]+/);
export const folderPageRegExp: RegExp = new RegExp(/(folders\/)((?:\d+[a-z]|[a-z]+\d)[a-z\d]*)+/g);
export const campaignPageRegExp: RegExp = new RegExp(/(campaign\/)/g);
export const candidateIdRegExp: RegExp = new RegExp(/(?:\d+[a-z]|[a-z]+\d)[a-z\d]*/g);
export const invitePageRegExp: RegExp = new RegExp(/auth\/invite\/[a-zA-Z0-9]+/gi);

export const redirectLinkRegExp: RegExp = new RegExp(/\/redirect\/link\/[0-9a-z]+/g);
export const cutRedirectLinkRegExp: RegExp = new RegExp(/redirect\/link\/[0-9a-z]+/g);

// Search:
export const searchQueryParamRegExp: RegExp = /\(([^)]+)\)|(NOT\(([^)]+)\))/g;
export const queryExcludeOperatorRegExp: RegExp = /NOT\(/g;

export const searchQuerySeparator = new RegExp(/\[[0-9]{1}\]:/);
export const emojiRegExp = new RegExp(
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
);
export const searchItemQuerySeparator = /\[|\]|:/g;

export const multiSegmentPatternRegExp = new RegExp(/\b\w+_[a-z0-9]+_[a-z]+\b/g);
export const searchValidIdentifiersRegExp = new RegExp(/\b(?!OR\b)(?!AND\b)[a-zA-Z]+\b(?![_])/g);
